// import React,{useEffect, useState, useRef, Children} from "react"
// import { useImageSize } from 'react-image-size';
// import Footer from "./Footer";
// import NavigationBar from "./NavigationBar";

// const asset_link = "https://tychon-api-development.herokuapp.com/assets/"
// function Layout(props) {

//     return (
//         <div>
//           <NavigationBar />
//          {props.children}
//           <Footer />
//         </div>
//        )
// }

// export default Layout;

import React from "react";
import tw from "twin.macro";
import styled from "styled-components";

const Container = tw.div`text-gray-600 -mx-8 -mb-8`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const Row = tw.div`flex items-center justify-center flex-col px-8`;

const LogoContainer = tw.div`flex items-center justify-center`;
const LogoImg = tw.img``;
const LogoText = tw.h5`ml-2 text-2xl font-black tracking-wider`;

const Divider = tw.div`my-16 border-b-2 border-gray-300 w-full`;

const LinksContainer = tw.div`mt-8 font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row`;
const Link = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-300 pb-1 transition duration-300 mt-2 mx-4`;

const SocialLinksContainer = tw.div`mt-10`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block text-gray-600 hover:text-gray-900 transition duration-300 mx-4`}
  svg {
    ${tw`w-5 h-5`}
  }
`;

const CopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm text-gray-600`;
export default () => {
  return (
    <Container>
      <Content>
        <Row>
          <LogoContainer>
            <a href="/">
              <LogoImg src={"./logo.png"} />
            </a>
          </LogoContainer>
          <LinksContainer>
            {/* <Link href="/">Home</Link>
            <Link href="/about">About</Link>
            <Link href="/contact">Contact Us</Link> */}
            <Link href="https://www.tychon.app/privacy-policy">
              Privacy Policy
            </Link>
            <Link href="https://www.tychon.app/terms-of-service">
              Terms of Service
            </Link>
          </LinksContainer>
          {/* <SocialLinksContainer>
            <SocialLink target="_blank" href="https://facebook.com/tychonapp">
              <FacebookIcon />
            </SocialLink>
            <SocialLink target="_blank" href="https://instagram.com/tychonapp">
              <InstagramIcon />
            </SocialLink>
            <SocialLink
              target="_blank"
              href="https://www.linkedin.com/company/tychonapp"
            >
              <LinkedInIcon />
            </SocialLink>
            <SocialLink target="_blank" href="https://twitter.com/tychonapp">
              <TwitterIcon />
            </SocialLink>
            <SocialLink target="_blank" href="http://www.tiktok.com/@tychonapp">
              <TikTokIcon />
            </SocialLink>
          </SocialLinksContainer> */}
          <CopyrightText>
            &copy; Copyright {new Date().getFullYear()}, All Rights Reserved.
          </CopyrightText>
        </Row>
      </Content>
    </Container>
  );
};
