// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pulse {
	border-radius: 50%;
	/* background: black;
	margin: 10px;
	height: 20px;
	width: 20px; */

	box-shadow: 0 0 0 0 rgba(0, 149, 143, 1);
	transform: scale(1);
	animation: pulse 2s infinite;
}

@keyframes pulse {
	0% {
		transform: scale(0.90);
		box-shadow: 0 0 0 0 rgba(0, 149, 143, 0.8);
	}

	50% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 149, 143, 0);
	}

	100% {
		transform: scale(0.90);
		box-shadow: 0 0 0 0 rgba(0, 149, 143, 0);
	}
}
`, "",{"version":3,"sources":["webpack://./src/pages/Home.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;CAClB;;;eAGc;;CAEd,wCAAwC;CACxC,mBAAmB;CACnB,4BAA4B;AAC7B;;AAEA;CACC;EACC,sBAAsB;EACtB,0CAA0C;CAC3C;;CAEA;EACC,mBAAmB;EACnB,2CAA2C;CAC5C;;CAEA;EACC,sBAAsB;EACtB,wCAAwC;CACzC;AACD","sourcesContent":[".pulse {\n\tborder-radius: 50%;\n\t/* background: black;\n\tmargin: 10px;\n\theight: 20px;\n\twidth: 20px; */\n\n\tbox-shadow: 0 0 0 0 rgba(0, 149, 143, 1);\n\ttransform: scale(1);\n\tanimation: pulse 2s infinite;\n}\n\n@keyframes pulse {\n\t0% {\n\t\ttransform: scale(0.90);\n\t\tbox-shadow: 0 0 0 0 rgba(0, 149, 143, 0.8);\n\t}\n\n\t50% {\n\t\ttransform: scale(1);\n\t\tbox-shadow: 0 0 0 10px rgba(0, 149, 143, 0);\n\t}\n\n\t100% {\n\t\ttransform: scale(0.90);\n\t\tbox-shadow: 0 0 0 0 rgba(0, 149, 143, 0);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
