import React, { useEffect, useState, useRef } from "react";
import { useImageSize } from "react-image-size";

const asset_link = "https://tychon-test-storage.s3.amazonaws.com/";
function ListItem({ user_image, coke_filename, bounding_box }) {
  const imgRef = useRef();

  const markerSize = 40;
  const [dimensions, { loading, error }] = useImageSize(
    asset_link + coke_filename
  );

  const [markPosition, setMarkPosition] = useState([]);
  useEffect(() => {
    let arrPoints = [];
    for (let i = 0; i < bounding_box.length; i++) {
      let topX = Object.values(bounding_box[i])[0].x1;
      let topY = Object.values(bounding_box[i])[0].y1;
      let bottomX = Object.values(bounding_box[i])[0].x2;
      let bottomY = Object.values(bounding_box[i])[0].y2;

      arrPoints.push({
        topX,
        topY,
        bottomX,
        bottomY,
      });
    }
    setMarkPosition(arrPoints);
    // const context = canvasRef.current.getContext('2d')
    // if(context) {
    //     console.log(context)
    //     context.fillStyle = "green";
    //     context.rect(arrPoints.topleft, arrPoints.topright, 150, 150 )
    // }
  }, [bounding_box, coke_filename]);

  return (
    <div style={{ position: "relative" }}>
      {markPosition.map((mp) => (
        <div
          className="pulse"
          style={{
            position: "absolute",
            width: markerSize + "px",
            opacity: 0.8,
            left: (mp.topX + mp.bottomX - markerSize) / 2,
            top: (mp.topY + mp.bottomY - markerSize) / 2,
          }}
        >
          <a
            target="_blank"
            href="https://tychon-dev.myshopify.com/products/coca-cola"
          >
            <img src="./logo-crosshairs.png" style={{ display: "block" }} />
          </a>
        </div>
      ))}

      <img
        ref={imgRef}
        src={asset_link + coke_filename}
        style={{
          width: dimensions?.width,
          height: dimensions?.height,
          display: "block",
        }}
      />
    </div>
  );
}

export default ListItem;
