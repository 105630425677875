import { Directus } from "@directus/sdk";
const directus = new Directus("https://saas-api.dev.tychon.app/", {
  auth: {
    staticToken: "r1bsZi_12xUOUCR-et3gsloTqMwFmwtW",
  },
});

export async function readData() {
  const showcase = directus.items("coke_showcase");
  let data = await showcase.readByQuery({
    limit: -1,
  });
  return data;
}
