import React,{useEffect, useState, useRef} from "react"
import MasonryList from "../components/MasonryList";
import Layout from "../components/Layout";
import "./Home.css"
function Home() {
   
    return(
        <Layout>

            <MasonryList />
        </Layout>
      );
}

export default Home;